@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap");

.challenge {
  /* height: 100vh; */
  width: 100vw;
  font-family: "Sora";

  overflow-y: hidden;
}
.challenge-heading {
  color: #03045e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;

  height: 10vh;
  margin-top: 20px;
}

.main-v-container {
  /* height: 40vh; */

  width: 90vw;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.Challenge-video-continer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
}
.Challenge-main-video {
  object-fit: cover;
  object-position: center;
  background-position: center;

  width: 100%;

  height: 100%;
  border-radius: 15px;
}
.Challenge-sub-video {
  display: flex;
  padding-top: 20px;
  padding: 20px;
  justify-content: space-around;
  height: 60%;
}
.main-video-btn {
  position: absolute;
  object-fit: cover;
  object-position: center;

  left: 50%;
  bottom: 50%;

  transform: translate(-50%, 50%);
  padding: 7px;

  display: flex;
  justify-content: space-evenly;
  color: #fec2c1;
  background-color: rgb(32, 50, 141, 0.7);
  border-radius: 100px;

  border: 2px solid #fec2c1;
  width: 20%;
  height: 20%;
  align-items: center;
  justify-content: space-evenly;
}

.card {
  width: 30%;
  position: relative;
  height: 31vh;
  scale: 1.08;
}
.video-time {
  position: absolute;
  bottom: 12vh;
  left: 4%;

  color: white;
  height: 20px;
  width: 50px;
  background-color: #03045e;
  border-radius: 50px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-bottom {
  display: flex;
  padding: 2%;
  justify-content: space-between;
}
.card-heading {
  color: #03045e;
  font-weight: 600;
  font: sans-serif;
  font-size: 25px;
  margin-top: 6px;
}
.sub-img {
  border-radius: 20px;
  object-fit: cover;
  object-position: center;
  height: 20vh;
  width: 100%;
  overflow: hidden;
}
.card-botton {
  color: white;
  height: 50%;
  width: 20%;
  background-color: #03045e;
  border-radius: 15%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-video-text {
  font-size: 17px;

  color: #fec2c1;
}

.challengem {

  width: 100vw;
  font-family: "Sora";

  overflow-y: hidden;
}

.main-v-containerm {
  height: 30vh;

  width: 90vw;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.Challenge-video-continerm {
  display: flex;
  flex-direction: column;
  width: 100vw;

  /* margin-left: 5%;
   margin-right: 5%; */
}
.Challenge-main-videom {
  object-fit: cover;
  /* object-position: center; */
  width: 100%;

  height: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
}
.Challenge-sub-videom {
  display: flex;

  justify-content: center;
  align-items: center;

  flex-wrap: wrap;
}

.main-video-btnm {
  position: absolute;
  object-fit: cover;
  object-position: center;

  left: 50%;
  bottom: 50%;

  transform: translate(-50%, 50%);
  display: flex;
  color: #fec2c1;
  background-color: rgb(32, 50, 141, 0.7);
  border-radius: 100px;

  border: 2px solid #fec2c1;
  width: 70%;
  height: 25%;
  align-items: center;
  justify-content: space-evenly;
}

.video-timem {
  position: absolute;
  bottom: 32%;
  left: 4%;

  color: white;
  height: 20px;
  width: 50px;
  background-color: #03045e;
  border-radius: 50px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-bottomm {
  display: flex;
  padding: 2%;
  justify-content: space-between;
}
.card-headingm {
  color: #03045e;
  font-weight: 600;
  font: sans-serif;
  font-size: 22px;
  margin-top: 6px;
}
.sub-imgm {
  border-radius: 20px;
  object-fit: cover;
  object-position: center;
  height: 20vh;
  width: 100%;
  overflow: hidden;
}
.card-bottonm {
  color: white;
  height: 50%;
  width: 20%;
  background-color: #03045e;
  border-radius: 15%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-video-textm {
  font-size: 25px;

  color: #fec2c1;
}
