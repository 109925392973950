.footerm {
    display: flex;
    padding-top: 2%;
    margin-top: 0.5%;
    align-items: center;
    width: 100vw;
    flex-direction: column;
    gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.footer-itemsm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100vw;
    gap: 0px;
    font-family: sans-serif;
    font-weight: 100;
}

.foot-itemm:hover {
    color: #20328d;
}

.foot-itemm {
    color: #03045e;
}

.foot-sharem {
    display: flex;
    gap: 20px;
}

.footer-icom {
    height: 80%;
}

.foot-policym {
    font-size: 7px;
    text-align: center;
}