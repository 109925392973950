*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
   
    text-decoration: none;
}
*::selection{
    color: white;
    background-color: #03045e;;
}