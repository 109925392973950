.footer {
    display: flex;
    padding-top: 2%;
    margin-top: 0.5%;

    align-items: center;




    width: 100vw;
    flex-direction: column;
    gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.footer-items {

    display: flex;
    justify-content: space-evenly;
    width: 60vw;



    font-family: sans-serif;
    font-weight: 250;
}

.foot-item:hover {
    color: #20328d;


}

.foot-item {
    color: #03045e;


}

.foot-share {
    display: flex;
    gap: 20px;
}

.footer-ico {
    height: 90%;
}

.foot-policy {
    font-size: 10px;
}