.app-containerm {

 


    overflow: hidden;

 

    
    overflow-y: hidden;

    position: relative;
    z-index: 0;
   
  
  }
  .playing-videom{
 
  }

  
  .navbarm {
    margin-top: 25px;
   
  }
  

  
  .video-list-headingm {
    color: #03045e;
    margin-bottom: 20px;
    
  
  
  }
  
  .video-itemm {
   
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 10px;
    color: #03045e;
    
  }
  
  .video-itemm:hover {
    background-color: rgb(0, 0, 0, 0.15);
    color: #03045e;
  
  }
  

  
  .selected-videom {
    width:60vw;
  
    position: relative;
    z-index: 0;
    


    
  
  
    height: 80%;
  
  }