.player-wrapper {
  width: 90vw;
  margin-left: -12vh;
}
.heading {
  color: #03045e;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 50px;
  margin-top: -25px;
}

.video {
  overflow: hidden;
}
.custom-video-player {
  position: relative;
  overflow: hidden;
  width: 80vw;

  border-radius: 26px;

  object-fit: cover;
  object-position: center;
  background-color: black;
}

.custom-video-player.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  border-radius: 0px;
  margin: 0;
}

.form-container {
  /* margin-left: 100px; */

  margin-top: 20px;
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  color: #03045e;

  user-select: none;

  flex-direction: column;
  border-radius: 20px;
  background-color: rgb(0, 0, 0, 0.05);
  padding: 20px;
  width: 55vw;
}
.form-container.fullscreen {
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  border-radius: 20px;
  color: white;
  padding: 20px;
  top: 24vh;
  left: 10vw;
  user-select: none;
  display: block;
  overflow-y: scroll;
  width: 80vw;
  justify-content: space-around;
  align-items: space-around;
}

.form-Qus {
  margin-top: 20px;
}
.form-Qus.fullscreen {
  text-align: center;
}

.form-container > div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 30px;
  margin-top: 40px;
}

.form-btn {
  color: white;
  background-color: #03045e;
  border-radius: 5px;
  height: 40px;
  width: 90px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.form-Op {
  cursor: pointer;
}
.answer-container {
  background-color: rgb(0, 0, 0, 0.1);
  margin-top: 20px;

  border-radius: 15px;

  padding: 20px;

  user-select: none;

  width: 55vw;
}
.answer-container.fullscreen {
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  border-radius: 20px;
  color: white;
  padding: 20px;
  bottom: 10vh;
  left: 5vw;
  user-select: none;

  width: 80vw;

  justify-content: space-around;
  align-items: space-around;
}
.ans-c {
  color: green;
  margin-bottom: 10px;
}
.ans-w {
  color: red;
  margin-bottom: 10px;
}
.ans-btn-con {
  width: 100%;
  display: flex;
  justify-content: center;
}
.ansPlayBtn {
  background-color: #03045e;
  color: white;
  width: 100px;
  height: 40px;
  margin-top: 20px;
}
.ans-sumbit-btn-con {
  width: 100%;
  display: flex;
  justify-content: center;
}

.styled-radio {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 4px solid #999;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.styled-radio:checked {
  border-color: #03045e;
  background-color: #03045e;
  scale: 1.1;
}
.option {
  background-color: white;
}
.custom-controls {
  display: flex;
  align-items: center;
  z-index: 10;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  width: 80vw;
  color: #03045e;
  height: 7vh;

  border-radius: 20px;
  background-color: rgb(0, 0, 0, 0.1);
}
.custom-controls.fullscreen {
  width: 95vw;
  padding-left: -90px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: white;
}
.btn-time {
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
}
.fullscreen-speed {
  user-select: none;
  display: flex;
  flex-direction: row-reverse;
  width: 130px;
  align-items: center;
  justify-content: space-between;
}
.fullscreen {
  font-size: 20px;
}
.speed {
  background-color: transparent;
  cursor: pointer;

  border: none;
  color: #03045e;

  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 5px;
  font-size: 20px;

  box-shadow: none;
}
.play {
  font-size: 40px;
  cursor: pointer;
  margin-top: 10px;
  scale: 0.9;
}
.des-container {
  display: flex;
  flex-direction: column;
  color: #03045e;
  width: 70vw;
  gap: 20px;
  margin-top: 20px;
  background-color: rgb(0, 0, 0, 0.05);
  padding: 30px;
  border-radius: 25px;
  z-index: 5;
  margin-left: -30px;
}
.des-container.fullscreen {
  display: none;
}
.video-list {
  width: 10%;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  overflow-y: auto;
  height: 70vh;
  margin-top: 13vh;
  width: 400px;
  position: absolute;
  z-index: 20;
  top: 124px;
  right: 0;
}
.sidebar-toggle-button {
  position: absolute;
  right: 40px;
  top: 245px;
  z-index: 22;
  background-color: transparent;
  color: black;
  box-shadow: none;
  border: none;
  scale: 1.5;
}
