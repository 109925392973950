@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap');


.challengem{
    
    width: 100vw;
    font-family: "Sora";
   
    overflow-y: hidden;
  
   
}
.challenge-headingm{
    color:  #03045e;
  
   
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;

    height: 10vh;
    
}
.main-v-containerm{
    height: 30vh;

    width: 90vw;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    


}
.Challenge-video-continerm{
    display: flex;
    flex-direction: column;
    width: 100vw;
    
   /* margin-left: 5%;
   margin-right: 5%; */
    
    
}
.Challenge-main-videom{
    object-fit: cover;
    /* object-position: center; */
    width: 100%;

    height: 100%;
    border-radius: 15px;
    margin-bottom: 20px;
  

}
.Challenge-sub-videom{
    display: flex;
    padding-top: 5%;
    justify-content: center;
    align-items: center;
    /* height: 50vh; */
    flex-wrap: wrap;
    
}

.main-video-btnm{
    position: absolute;
    object-fit: cover;
    object-position: center;
    
   
    left: 50%;
    bottom: 50%;
    
    transform: translate(-50%,50% );
    display: flex;
    color: #FEC2C1;
    background-color:  rgb(32,50,141,0.7);
    border-radius:100px;
  
    border: 2px solid  #FEC2C1;
    width: 70%;
    height: 25%;
    align-items: center;
    justify-content: space-evenly;

    
    
   
}



.cardm{
    position: relative;
    border-radius: 20px;
    height: 60%;
    max-width: 95%;
    min-width: 95%;
    scale: 1.0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 35px;
    
}
.video-timem{
    position: absolute;
    bottom: 32%;
    left: 4%;
 
    color: white;
    height:20px;
    width: 50px;
    background-color: #03045e;
    border-radius: 50px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.card-bottomm{
    display: flex;
    padding: 2%;
    justify-content: space-between;

}
.card-headingm{
    color: #03045e;
    font-weight: 600;
    font:  sans-serif;
    font-size: 22px;
    margin-top: 6px;

}
.sub-imgm{
    border-radius: 20px;
    object-fit: cover;
    object-position: center;
    height: 20vh;
    width: 100%;
    overflow: hidden;
}
.card-bottonm{
   
 
    color: white;
    height: 50%;
    width: 20%;
    background-color: #03045e;
    border-radius: 15%;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    

}
.main-video-textm{
    
    font-size: 25px;
  
    color: #FEC2C1;
}

