.app-container {
 


  overflow: hidden;
  display: flex;
 
  
  padding-top: 40px;
  
  overflow-y: hidden;
  margin: 0;
  justify-content: space-around;
 

}

.navbar {
  margin-top: 25px;
 
}

/* .video-list {
  width: 10%;
  background-color: rgb(0, 0, 0, 0.05);
border-radius: 20px;
padding: 20px;
  overflow-y: auto;
  height: 100%;
  margin-top: 13vh;
  width: 400px;
  z-index: 0;
  
} */

.video-list-heading {
  color: #03045e;
  margin-bottom: 20px;
  


}

.video-item {
 
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  color: #03045e;
}

.video-item:hover {
  background-color: rgb(0, 0, 0, 0.15);
  color: #03045e;

}

.selected {
  background-color: #03045e;
  color: white;
}

.selected-video {
  width:70vw;
  padding: 20px;


  height: 80%;
  z-index: 11;

}