* {
  padding: 0;
}

.full-wrapper {
  height: 100vh;
  overflow-y: hidden;
  width: 100vw;
}

.headingm {
  color: #03045e;
  font-size: 40px;
  font-weight: 600;
  display: none;
}

.videom {
  overflow: hidden;
}

.custom-video-playerm {
  position: relative;
  overflow: hidden;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  object-position: center;
  background-color: black;
}

.custom-video-playerm.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 99.5vh;
  z-index: 1;
  border-radius: 0px;
  margin: 0;
}

.list-top {
  display: flex;
}

.video-list-btn {
  height: 20px;
  font-family: sans-serif;
  font-size: 20px;
  flex:1
}

.video-list-btn {
  margin-bottom: 20px;
  color: #03045e;
}

.form-containerm {
  margin-top: 20px;
  width: 95vw;
  flex-wrap: wrap;
  color: #03045e;
  user-select: none;
  border-radius: 20px;
  background-color: rgb(0, 0, 0, 0.05);
  padding: 20px;
  margin: 5%;
}

.form-containerm.fullscreen {
  background-color: white;
  position: fixed;
  border-radius: 20px;
  /* display: flex; */
  color: black;
  padding: 20px;
  top: 0px;
  left: 0px;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 5vw;
  width: 60vw;
  display: block;
  overflow-y: scroll;
  align-items: space-around;
  margin-top: 1vh;
  /* max-height: 300px; */
  overflow-y: scroll;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  overflow-x: hidden;
  margin-left: 10vw;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  } */

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(174, 0, 87);
  border-radius: 100px;
  width: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.form-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-Qusm {
  margin-top: 20px;
  font-family: sans-serif;
  font-weight: bold;
}

.form-Qusm.fullscreen {
  font-weight: bold;
  font-family: sans-serif;
}

.form-containerm > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin-right: 30px; */
}

.form-o-b {
  display: flex;
  gap: 15px;
}

.form-btnm {
  color: white;
  background-color: #03045e;
  border-radius: 5px;
  height: 40px;
  width: 90px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.form-btnm.fullscreen {
}

.form-btn-div {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-btn-div.fullscreen {
}

.form-Opm {
  cursor: pointer;
  font-family: sans-serif;
}

.ans-sumbit-btn-con {
  width: 100%;
  display: flex;
  justify-content: center;
}

.answer-containerm {
  background-color: rgb(0, 0, 0, 0.1);
  margin: 5%;
  border-radius: 15px;
  padding: 20px;
  user-select: none;
  width: 95vw;
  font-family: sans-serif;
}

.answer-containerm.fullscreen {
  background-color: white;
  position: fixed;
  border-radius: 20px;
  color: black;
  padding: 20px;
  top: 0;
  left: 5vw;
  -webkit-user-select: none;
  user-select: none;
  font-size: 18px;
  width: 60vw;
  justify-content: space-around;
  align-items: space-around;
  margin-top: 30vh;
  max-height: 300px;
  overflow-y: scroll;
}

.ans-c {
  color: green;
  margin-bottom: 10px;
}

.ans-w {
  color: red;
  margin-bottom: 10px;
}

.ans-btn-con {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ansPlayBtn {
  background-color: #03045e;
  color: white;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  border: none;
}

.styled-radiom {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 4px solid #999;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
  padding: 5px;
}

.styled-radiom:checked {
  border-color: #03045e;
  background-color: #03045e;
  scale: 1.1;
}

.optionm {
  background-color: white;
}

.video-bottomm {
  background-color: transparent;
  width: 100vw;
  height: 20vh;
  margin-top: 5vh;
}

.custom-controlsm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  width: 100vw;
  position: relative;
  color: #03045e;
  height: 10vh;
  border-radius: 20px;
  background-color: transparent;
  position: absolute;
  background-color: transparent;
  color: #999;
  box-shadow: none;
  border: none;
  bottom: 5%;
  z-index: 22;
}

.custom-controlsm.fullscreen {
  margin-left: 5px;
  width: 100vw;
  position: fixed;
  bottom: 20px;
  color: white;
  font-size: medium;
}

.btn-timem.fullscreen {
}

.speedm.fullscreen {
  font-size: 12px;
}

.btn-timem {
  display: flex;
  min-width: 100px;
  justify-content: space-evenly;
  align-items: center;
  font-size: 12px;
}

#myRange {
  width: 90vw;
}

.fullscreen-speedm {
  user-select: none;
  display: flex;
  justify-content: space-evenly;
  min-width: 100px;
  align-items: center;
}

.fullscreen-btn {
  margin-top: 5%;
  /* font-size: 18px;
   
    margin-left: -5vw; */
}

#first-op {
  color: white;
}

.speedm {
  background-color: transparent;
  cursor: pointer;
  border: none;
  color: #03045e;
  /* min-width: 17vw; */
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* padding: 5px; */
  font-size: 15px;
  box-shadow: none;
}

.speedm.fullscreen {
  color: white;
}

/* .time-barm{

    width: 100px;
 
} */

.speedm-dropdown {
  top: -100%;
  /* Position the options above the select element */
}

.playm {
  font-size: 25px;
  cursor: pointer;
  margin-top: 7px;
  scale: 0.9;
}

.des-containerm {
  display: flex;
  flex-direction: column;
  color: #03045e;
  width: 95vw;
  gap: 20px;
  margin-top: 20px;
  background-color: rgb(0, 0, 0, 0.05);
  padding: 30px;
  border-radius: 25px;
  z-index: 5;
  margin: 5%;
  position: relative;
}

.des-containerm.fullscreen {
  display: none;
}

.video-listm {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  overflow-y: auto;
  width: 250px;
  position: absolute;
  z-index: 25;
  top: 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 40vh;
  border: 1px solid white;
  transition: right 2s ease;
}

.video-listm.fullscreen {
  position: fixed;
  font-size: 20px;
  z-index: 20;
  height: 100vh;
  width: 300px;
}

.video-list-close {
  height: 20px;
  text-decoration: underline;
}

.list-top {
  /* margin-bottom: 15px; */
}

.video-itemm:hover {
  background-color: rgb(0, 0, 0, 0.15);
  color: #03045e;
}

.selectedm {
  background-color: #03045e;
  color: white;
}

.video-itemm {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  color: #03045e;
  font-family: sans-serif;
  top: 500px;
}

::cue {
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  line-height: 0.1;
}

.sidebar-toggle-buttonm {
  position: absolute;
  background-color: transparent;
  color: #999;
  box-shadow: none;
  border: none;
  bottom: 85%;
  right: 10px;
  z-index: 22;
  width: 40px;
  scale: 2;
}

@media (orientation: portrait) {
  .form-containerm.fullscreen {
    width: 100%;
    margin-left: 0px;
    margin-top: 20vh;
    height: 300px;
    display: block;
 
    overflow-y: scroll;
    background-color: white !important;
    display: block;
  }
  .custom-controlsm {
    bottom: 20%;
  }
  .answer-containerm.fullscreen {
    width: 100%;
    margin-left: 0px;
    margin-top: 20vh;
    left: 0vw;
    top: 0;
    background-color: white !important;
  }
}
