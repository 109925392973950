* {
    z-index: 20;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: #ffffff;
    color: #ae0157;
    overflow-y: hidden;
}

nav a {
    margin: 0 1rem;
    color: #ae0157;
    text-decoration: none;
}

nav a:hover {
    color: yellowgreen;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: white;
    border: none;
    outline: none;
    color: white;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

header div,
nav {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1024px) {
    header .nav-logo {
        width: 88px;
        height: auto;
    }
    header .nav-btn {
        visibility: visible;
        opacity: 1;
        width: 33px;
        height: auto;
    }
    header .nav-btns {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    header .lang {
        gap: 0.6rem;
    }
    header .lang-menu {
        display: flex;
        align-items: center;
        line-height: 1.5rem;
        font-weight: 500;
        cursor: pointer;
        border: none;
        outline: none;
        color: #ae0157;
        background-color: #ffffff;
        margin-top: 1%;
    }
    header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: #ffffff;
        transition: 1s;
    }
    header .responsive_nav {
        transform: translateY(100vh);
    }
    nav .nav-close-btn {
        position: absolute;
        top: -10rem;
        right: 2rem;
    }
    nav a {
        font-size: 1.5rem;
    }
}